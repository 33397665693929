import React, { useState } from "react";
import "./Navbar.scss";
import { images } from "../../constants";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const menuVariants = {
    open: {
      clipPath: "circle(1200px at 90% 10%)",
      transition: {
        type: "spring",
        stiffness: 20,
        duration: 0.85,
      },
    },
    closed: {
      clipPath: "circle(30px at 90% 10%)",
      transition: {
        type: "spring",
        stiffness: 20,
        duration: 0.85,
      },
    },
  };
  const menuClosedVariants = {
    closed: {
      clipPath: "circle(30px at 90% 10%)",
      transition: {
        type: "spring",
        stiffness: 20,
        duration: 0.85,
      },
    },
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logoText} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {["home", "about", "work", "testimonial", "skills", "contact"].map(
          (item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          )
        )}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            className="menu-icon"
            initial="closed"
            animate={toggle ? "open" : "closed"}
            variants={toggle ? menuVariants : menuClosedVariants}
          >
            <HiX onClick={() => setToggle(false)} />
            <motion.ul>
              {[
                "home",
                "about",
                "work",
                "testimonial",
                "skills",
                "contact",
              ].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </motion.ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
