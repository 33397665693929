// LoadingCircle.js

import React from "react";
import "./LoadingCircle.scss";

const LoadingCircle = () => {
  return (
    <div className="loading-circle-container">
      <div className="loading-circle"></div>
    </div>
  );
};

export default LoadingCircle;
