import React from "react";
import { BsWhatsapp, BsGoogle } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://wa.link/z0v4p6" target="_blank" rel="noreferrer">
      <BsWhatsapp />
    </a>
    <a
      href="https://www.linkedin.com/in/ismail-ibn-muhammed-haneef-ip-aa12111ba/"
      target="_blank"
      rel="noreferrer"
    >
      <FaLinkedinIn />
    </a>
    <a href="mailto:ipismail303@.com" target="_blank" rel="noreferrer">
      <BsGoogle />
    </a>
  </div>
);

export default SocialMedia;
