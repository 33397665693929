import React, { useEffect, useState } from "react";
import "./Header.scss";
import { motion } from "framer-motion";
import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import { urlFor, client } from "../../client";
import LoadingCircle from "../../components/LoadingCircle/LoadingCircle";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => {
  const [heroData, setHeroData] = useState();

  useEffect(() => {
    const query = '*[_type == "siteSettings"]';

    client
      .fetch(query)
      .then((data) => {
        setHeroData(data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  });

  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, I am</p>
              <h1 className="head-text">Ismail</h1>
            </div>
          </div>
          <div className="tag-cmp app__flex">
            {!heroData ? (
              <LoadingCircle />
            ) : (
              heroData.tags.map((tag, index) => (
                <p className="p-text" key={tag + index}>
                  {tag}
                </p>
              ))
            )}
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        {heroData && <img src={urlFor(heroData.profile)} alt="profile_bg" />}

        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {!heroData
          ? ["", "", ""].map((circle, index) => (
              <div
                className="circle-cmp app__flex"
                key={`circle-${index}`}
              ></div>
            ))
          : heroData.skills.map((circle, index) => (
              <div className="circle-cmp app__flex" key={`circle-${index}`}>
                <img src={urlFor(circle)} alt="profile_bg" />
              </div>
            ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
