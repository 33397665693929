/* eslint-disable import/no-anonymous-default-export */
import email from '../assets/email.png';
import api from '../assets/api.png';
import circle from '../assets/circle.svg';
import ismail from '../assets/Ismail-profile.png';
import logoText from '../assets/logo-text.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import whatsapp from '../assets/whatsapp.png';

export default {
    email,
    api,
    react,
    redux,
    sass,
    circle,
    logoText,
    whatsapp,
    ismail,
};